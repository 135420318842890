<template>
  <div class="bg-black-600 flex flex-col gap-3.5 p-3.5 lg:gap-2.5 lg:p-6">
    <HeadlineGroup
      v-if="headline?.title || headline?.subtitle"
      v-bind="headline"
      headline-class="text-black-200 font-semibold"
    />
    <div
      v-if="title"
      class="font-mierb text-body text-black-200 font-semibold uppercase tracking-[0.7px]"
    >
      {{ title }}
    </div>
    <BaseSlider
      id="test"
      variant="pagination"
      class="mb-2"
      :class="wrapLinks.length === 1 && 'hide-dots'"
      :options="{
        ...sliderOptions,
        padding: {
          left: 0,
          right: 0,
        },
        classes: {
          pagination: paginationAlignment,
        },
      }"
    >
      <SplideSlide v-for="(link, index) in wrapLinks" :key="index">
        <div class="mr-5 h-full">
          <Wraplink
            class="h-full !items-center !p-0 hover:bg-transparent"
            v-bind="link"
            button-custom-style="width: 100%; justify-content: center;"
          />
        </div>
      </SplideSlide>
    </BaseSlider>
  </div>
</template>
<script lang="ts" setup>
import { SplideSlide } from "@splidejs/vue-splide";
import { computed as vComputed } from "vue";
import { PropType } from "nuxt/dist/app/compat/capi";
import { SplideOptions } from "../BaseSlider/types";
import { splideDefaultOptions } from "../BaseSlider/const";
import { wraperListInterface } from "./types";
import BaseSlider from "~/components/BaseSlider/BaseSlider.vue";
import { getSliderItemsPerPage } from "~/utils";

const props = defineProps({
  title: {
    type: String,
    default: undefined,
  },
  headline: {
    type: Object,
    default: () => ({}),
  },
  sliderOptions: {
    type: Object as PropType<SplideOptions>,
    default: splideDefaultOptions,
  },
  wrapLinks: {
    type: Array as PropType<wraperListInterface[]>,
    default: () => [],
  },
  paginationPosition: {
    type: String,
    default: "left",
  },
});

const showPerPage = vComputed(() => getSliderItemsPerPage(props.sliderOptions));

const paginationAlignment = vComputed(() =>
  props.paginationPosition === "left"
    ? "splide__pagination justify-start" + " smaller-width"
    : props.paginationPosition === "center"
    ? "splide__pagination justify-center" + " smaller-width"
    : "splide__pagination justify-end" + " smaller-width"
);
</script>
<style lang="scss">
.hide-dots {
  .splide__pagination {
    visibility: hidden !important;
  }
}
</style>
